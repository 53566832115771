import React from 'react';
import "./App.css";
import logo from './assets/logo.png';

class App extends React.Component {
  render = () => {
    return (
      <div className="card">
        
        <div className="content">
        <div className="logo">
            <a href="."><img src={logo} alt="Logo" /></a>
        </div>
          <div className="title-holder">
            <h1>Get ready for the change.</h1>
            <p>Our Website is being built and will be launched soon. </p>
          </div>
          
        </div>
        <div className="footer">
          <span>made by <a href="https://modiclestudios.com"> Modicle Studios</a></span>
        </div>
      </div>
    );
  }
}

export default App;